import { Trash } from "@phosphor-icons/react";
import React from "react";

import Skeleton from "common/components/ui/Skeleton";
import ProfileImage from "common/components/ui/ProfileImage";
import { getProfileSubtitle } from "common/helpers/utils";
import RestrictedComponent from "common/components/RestrictedComponent";
import { Button } from "common/components/ui/Button";
import { LinkedInProfile } from "common/types";

export default function BlacklistAccountItem({
  profile,
  onRemove,
  isLoading,
}: {
  profile: LinkedInProfile;
  onRemove: () => void;
  isLoading: boolean;
}) {
  return (
    <div className="flex flex-row items-center justify-between rounded-2xl border border-black-200 px-3 py-3.5">
      <div className="flex flex-row items-center gap-3">
        {isLoading ? (
          <Skeleton className="size-12 rounded-xl" />
        ) : (
          <ProfileImage size="lg" src={profile.picture_url} />
        )}

        <div className="flex flex-col gap-0.5">
          {isLoading ? (
            <>
              <Skeleton className="h-5 w-44" />
              <Skeleton className="h-4 w-24" />
            </>
          ) : (
            <>
              <span className="text-headline-lg-bold">{profile.full_name}</span>
              <span className="line-clamp-1 text-black-500">
                {getProfileSubtitle(profile)}
              </span>
            </>
          )}
        </div>
      </div>

      <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
        {isLoading ? (
          <Skeleton className="size-8 rounded-full" />
        ) : (
          <Button
            intent="iconOnly"
            variant="quaternary-danger"
            onClick={onRemove}
            data-cy="remove-from-blacklist-button"
          >
            <Trash />
          </Button>
        )}
      </RestrictedComponent>
    </div>
  );
}
