import {
  CaretDown,
  CaretUp,
  CaretUpDown,
  NoteBlank,
} from "@phosphor-icons/react";
import React, { MouseEvent } from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import ProfileImage from "common/components/ui/ProfileImage";
import { Tag } from "common/components/ui/Tag";
import { Lead } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import LabelTag from "common/components/LabelTag";

import LeadMenu from "./LeadMenu";

const maximumLabelsDisplayed = 3;

export default function LeadsTable({
  leads,
  isLoading,
  openPreview,
  orderSort,
  setOrderSort,
}: {
  leads: Lead[];
  isLoading: boolean;
  openPreview: (lead: Lead) => void;
  orderSort: "asc" | "desc" | undefined;
  setOrderSort: (sort?: "asc" | "desc") => void;
}) {
  function toggleOrderSort() {
    if (orderSort === "asc") {
      setOrderSort("desc");
    } else if (orderSort === "desc") {
      setOrderSort();
    } else {
      setOrderSort("asc");
    }
  }
  function onRowClick(e: MouseEvent<HTMLTableRowElement>, lead: Lead) {
    if (isLoading) return;
    if (
      !e.nativeEvent
        .composedPath()
        .some((element) =>
          ["lead-menu-trigger", "lead-menu-content"].includes(
            (element as Element).id,
          ),
        )
    ) {
      openPreview(lead);
    }
  }
  let sortIcon = <CaretUpDown className="size-4" />;
  if (orderSort === "asc") {
    sortIcon = <CaretUp className="size-4" />;
  } else if (orderSort === "desc") {
    sortIcon = <CaretDown className="size-4" />;
  }
  return (
    <Table className="table-fixed">
      <TableHeader>
        <tr>
          <TableHead className="w-5/12">
            <button
              type="button"
              className="flex items-center gap-x-1"
              onClick={toggleOrderSort}
            >
              Lead {sortIcon}
            </button>
          </TableHead>
          <TableHead className="w-1/6 text-left">Connected to</TableHead>
          <TableHead className="text-left">Labels</TableHead>
          <TableHead className="w-[60px]" />
        </tr>
      </TableHeader>
      <tbody>
        {(isLoading ? new Array<undefined>(8).fill(undefined) : leads).map(
          (lead, index) => (
            <TableRow
              key={lead ? lead.urn : index}
              onClick={(e) => onRowClick(e, lead)}
            >
              <TableCell>
                <div className="flex items-center gap-x-3">
                  {lead ? (
                    <ProfileImage src={lead.picture_url} />
                  ) : (
                    <Skeleton className="size-10 shrink-0" />
                  )}
                  <div className="flex flex-col gap-y-0.5">
                    <div className="flex items-center gap-x-0.5">
                      {lead ? (
                        <span className="line-clamp-1 text-body-14-bold">
                          {lead.full_name}
                        </span>
                      ) : (
                        <Skeleton className="h-5 w-32" />
                      )}
                      {lead && !!lead.notes.length && (
                        <Tag variant="yellow" leftIcon={<NoteBlank />}>
                          {lead.notes.length}
                        </Tag>
                      )}
                    </div>
                    {lead ? (
                      <span className="line-clamp-1 text-caption-12-regular text-black-500">
                        {lead.occupation}
                      </span>
                    ) : (
                      <Skeleton className="h-4 w-48" />
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell>
                {isLoading ? (
                  <Skeleton className="h-4 w-32 rounded-full" />
                ) : (
                  <div className="flex">
                    <LinkedInAccountsTag accountIds={lead.lead_of} size="sm" />
                  </div>
                )}
              </TableCell>
              <TableCell>
                {lead && leads.length && (
                  <div className="flex items-center gap-1">
                    {(lead.labels.length > maximumLabelsDisplayed
                      ? lead.labels.slice(0, maximumLabelsDisplayed - 1)
                      : lead.labels
                    ).map((label) => (
                      <LabelTag label={label} key={label.id} />
                    ))}
                    {lead.labels.length > maximumLabelsDisplayed && (
                      <Tag variant="quaternary">
                        {`+${lead.labels.length - (maximumLabelsDisplayed - 1)} more`}
                      </Tag>
                    )}
                  </div>
                )}
              </TableCell>
              <TableCell>
                {lead ? (
                  <LeadMenu lead={lead} />
                ) : (
                  <Skeleton className="ml-auto size-10 rounded-full" />
                )}
              </TableCell>
            </TableRow>
          ),
        )}
      </tbody>
    </Table>
  );
}
