import React, { useState } from "react";
import { Envelope, User, XLogo, LinkedinLogo } from "@phosphor-icons/react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import ProfileImage from "common/components/ui/ProfileImage";
import { DetailedLead, Lead } from "common/types";
import { Button } from "common/components/ui/Button";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import LeadLabels from "common/components/LeadLabels";
import Skeleton from "common/components/ui/Skeleton";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import MessageLeadDialog from "common/components/MessageLeadDialog";
import LeadSource from "common/components/LeadPreview/LeadSource";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import RenderIf from "../RenderIf";
import { ConversationParticipant } from "../../../pages/inbox/types";

interface LeadHeadingProps {
  lead: ConversationParticipant | DetailedLead | Lead;
  isPreview: boolean;
  isLoading: boolean;
  canSendMessage: boolean;
}

export default function LeadHeading({
  lead,
  isPreview,
  isLoading,
  canSendMessage,
}: LeadHeadingProps) {
  const { relativeNavigate } = useWorkspaceNavigate();
  const [isMessagingDialogOpen, setIsMessagingDialogOpen] = useState(false);
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const isLead = lead && (!("is_lead" in lead) || lead.is_lead);

  return (
    <div className="flex flex-col gap-4 rounded-20 bg-white p-4">
      <div className="flex">
        {isLoading ? (
          <Skeleton className="mr-2 size-12 rounded-2xl" />
        ) : (
          <ProfileImage size="lg" className="mr-2" src={lead.picture_url} />
        )}

        <div className="flex flex-col gap-y-0.5">
          {isLoading ? (
            <Skeleton className="h-5 w-24" />
          ) : (
            <span className="text-button-16">{lead.full_name}</span>
          )}

          {isLoading ? (
            <Skeleton className="h-4 w-40" />
          ) : (
            <span className="line-clamp-2 text-caption-12-regular text-black-600">
              {lead.occupation}
            </span>
          )}

          {/*
           This condition is requested by backend, so we need to check if it's an empty array,
           because they are having issues with the API and it's returning undefined or empty array
          */}
          <RenderIf condition={!!lead?.origins?.length}>
            <div className="flex shrink-0 items-center gap-x-1 gap-y-0.5">
              {isLoading ? (
                <Skeleton className="h-3 w-36" />
              ) : (
                <LeadSource origins={lead.origins} />
              )}
            </div>
          </RenderIf>
        </div>
      </div>

      <div className="flex gap-2 border-b border-b-black-200 pb-3">
        {isLoading ? (
          <Skeleton className="h-6 w-36 rounded-full" />
        ) : (
          <LinkedInAccountsTag accountIds={lead.lead_of} />
        )}
      </div>

      {lead && isLead && (
        <>
          <MessageLeadDialog
            lead={lead}
            isOpen={isMessagingDialogOpen}
            onClose={() => setIsMessagingDialogOpen(false)}
          />
          <LeadLabels labels={lead.labels} profileId={lead.id} />
        </>
      )}

      <RenderIf condition={isLead}>
        <div className="flex justify-between gap-2">
          <div
            className={clsx([
              "grid w-full grid-cols-1 gap-2",
              canSendMessage && "md:grid-cols-2",
            ])}
          >
            {isPreview && (
              <>
                {isLoading && (
                  <Skeleton
                    className={clsx(
                      "h-10 min-w-52 rounded-full",
                      isPreview && "flex-1",
                    )}
                  />
                )}

                <RenderIf condition={!isLoading}>
                  <Link
                    to={`/workspaces/${workspaceId}/leads/${lead.id}`}
                    className="w-full"
                  >
                    <Button
                      variant="secondary-purple"
                      leftIcon={<User />}
                      className="w-full"
                    >
                      See full profile
                    </Button>
                  </Link>
                </RenderIf>
              </>
            )}

            <RenderIf condition={canSendMessage}>
              {isLoading ? (
                <Skeleton
                  className={clsx(
                    "h-10 min-w-52 rounded-full",
                    isPreview && "flex-1",
                  )}
                />
              ) : (
                <Button
                  variant="primary-black"
                  leftIcon={<Envelope />}
                  onClick={
                    lead.lead_of.length > 1
                      ? () => setIsMessagingDialogOpen(true)
                      : () => relativeNavigate("inbox", { state: { lead } })
                  }
                >
                  Send message
                </Button>
              )}
            </RenderIf>
          </div>

          <div className={clsx("flex gap-x-2", isPreview && "hidden")}>
            {isLoading ? (
              <Skeleton className="size-10 rounded-full" />
            ) : (
              <Button variant="quaternary-black" intent="iconOnly" asChild>
                <a
                  href={`https://linkedin.com/in/${lead.public_identifier}`}
                  target="_blank"
                  aria-label="go to linkedin page"
                  rel="noreferrer"
                >
                  <LinkedinLogo weight="fill" />
                </a>
              </Button>
            )}

            {lead && lead.twitter_handles.length ? (
              <Button asChild variant="quaternary-black" intent="iconOnly">
                <a
                  href={`https://twitter.com/${lead.twitter_handles[0]}`}
                  aria-label="go to twitter page"
                  target="_blank"
                  rel="noreferrer"
                >
                  <XLogo />
                </a>
              </Button>
            ) : null}
          </div>
        </div>
      </RenderIf>
    </div>
  );
}
