import { useState } from "react";

import { Campaign, CampaignState } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function useCampaignsTable(campaigns: Campaign[]) {
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [campaignState, setCampaignState] = useState<CampaignState>(null);

  const selectedWorkspace = useSelectedWorkspaceContext();

  const filteredCampaigns = campaigns
    ? campaigns.filter(({ name, state, owner }) => {
        const isMatchingName = searchText
          ? name.trim().toLowerCase().includes(searchText.trim().toLowerCase())
          : true;
        const isMatchingRole =
          selectedWorkspace.role === "member" ? state !== "INIT" : true;
        const isMatchingState = campaignState ? state === campaignState : true;
        const isMatchingOwner = selectedAccountIds.length
          ? selectedAccountIds.includes(owner)
          : true;
        return (
          isMatchingName && isMatchingState && isMatchingOwner && isMatchingRole
        );
      })
    : [];

  function clearFilters() {
    setSearchText("");
    setCampaignState(null);
    setSelectedAccountIds([]);
  }

  return {
    searchText,
    setSearchText,
    campaignState,
    setCampaignState,
    selectedAccountIds,
    setSelectedAccountIds,
    filteredCampaigns,
    hasFilters: !!searchText || !!campaignState || !!selectedAccountIds.length,
    clearFilters,
  };
}
