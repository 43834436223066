import React, { ComponentProps } from "react";

import ProfileImage from "common/components/ui/ProfileImage";
import { getProfileSubtitle } from "common/helpers/utils";

import GroupConversationAvatar from "./GroupConversationAvatar";
import { Conversation } from "../types";
import { getGroupParticipantsString } from "../utils";
import RenderIf from "../../../common/components/RenderIf";

export default function ParticipantInfo({
  conversation,
  profileSize = "sm",
  showOrigins = false,
}: {
  conversation: Conversation;
  profileSize?: ComponentProps<typeof ProfileImage>["size"];
  showOrigins?: boolean;
}) {
  const groupParticipantsString = getGroupParticipantsString(conversation);
  const { participants } = conversation;

  return (
    <div className="flex flex-row items-center gap-1">
      {groupParticipantsString ? (
        <GroupConversationAvatar participants={participants} />
      ) : (
        <ProfileImage size={profileSize} src={participants[0].picture_url} />
      )}

      <div className="ml-2 flex flex-col items-start justify-center text-start">
        <span className="line-clamp-1 max-w-[40ch] text-button-14">
          {groupParticipantsString || participants[0].full_name}
        </span>

        <p className="flex flex-row flex-wrap items-center gap-1 text-caption-12-regular text-black-600">
          <span className="line-clamp-1 max-w-[275px]">
            {groupParticipantsString
              ? `${participants.length} members`
              : getProfileSubtitle(participants[0])}
          </span>

          <RenderIf condition={showOrigins && !groupParticipantsString}>
            | Origin:{" "}
            <span className="max-w-[50ch] truncate text-caption-12-bold">
              {participants[0]?.origins[0]?.name}
            </span>
          </RenderIf>
        </p>
      </div>
    </div>
  );
}
