import React, { useEffect } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import useCampaigns from "common/datahooks/useCampaigns";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import NoCampaignsPlaceholder from "common/components/NoCampaignsPlaceholder";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import useAppStore from "common/hooks/useAppStore";

import useCampaignsTable from "./useCampaignsTable";
import StateFilter from "./StateFilter";
import CampaignsTable from "./CampaignsTable";
import MobileFilters from "./MobileFilters";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function CampaignsList() {
  const isDesktop = useTwBreakpoint("lg");
  const isTabletOrDesktop = useTwBreakpoint("md");
  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );
  const { accounts } = useSelectedWorkspaceContext();

  const isEveryAccountLoggedOut = accounts.every(
    ({ state }) => state !== "LoggedIn",
  );

  const { campaigns, isLoadingCampaigns, campaignsError, refetchCampaigns } =
    useCampaigns();

  const {
    filteredCampaigns,
    hasFilters,
    searchText,
    setSearchText,
    campaignState,
    setCampaignState,
    clearFilters,
    selectedAccountIds,
    setSelectedAccountIds,
  } = useCampaignsTable(campaigns);

  // User can't create a new campaign if all LinkedIn accounts are logged out
  const createCampaignComponent = isEveryAccountLoggedOut ? (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          data-intercom-target="create-campaign-button"
          leftIcon={<PlusCircle weight="fill" />}
          disabled
        >
          Create campaign
        </Button>
      </TooltipTrigger>

      <TooltipContent>
        You must have at least one connected LinkedIn account to create a
        campaign
      </TooltipContent>
    </Tooltip>
  ) : (
    <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
      {(isDisabled) => (
        <Button
          data-intercom-target="create-campaign-button"
          onClick={() => setIsCampaignModalOpen(true)}
          leftIcon={<PlusCircle weight="fill" />}
          disabled={isDisabled}
        >
          Create campaign
        </Button>
      )}
    </RestrictedComponent>
  );

  useEffect(() => {
    if (!isDesktop) {
      useAppStore
        .getState()
        .setHeaderContent({ actionComponent: createCampaignComponent });
      return () => useAppStore.getState().setHeaderContent(null);
    }
  }, [isDesktop]);

  if (campaignsError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Campaigns"
        onRetry={refetchCampaigns}
      />
    );
  }
  if (!isLoadingCampaigns && (!campaigns || !campaigns.length)) {
    return <NoCampaignsPlaceholder />;
  }

  const currentPageCampaigns = filteredCampaigns.length
    ? filteredCampaigns
    : new Array(7).fill({});

  return (
    <>
      {/* Top Section */}
      <div className="flex flex-col-reverse items-center justify-between md:flex-row">
        <div className="flex w-full items-center gap-2">
          <SearchInput
            className="max-md:flex-1 md:w-80"
            placeholder="Search campaign"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onClear={() => setSearchText("")}
          />

          {isTabletOrDesktop ? (
            <>
              <StateFilter
                selectedFilter={campaignState}
                setFilter={setCampaignState}
                campaigns={campaigns}
              />

              <WorkspaceAccountSelector
                className="w-44"
                selectedAccountIds={selectedAccountIds}
                setSelectedAccountIds={setSelectedAccountIds}
              />
            </>
          ) : (
            <MobileFilters
              campaigns={campaigns}
              currentFilters={{ selectedAccountIds, campaignState }}
              clearFilters={clearFilters}
              applyFilters={(newFilters) => {
                setCampaignState(newFilters.campaignState);
                setSelectedAccountIds(newFilters.selectedAccountIds);
              }}
            />
          )}
        </div>
        {isDesktop && createCampaignComponent}
      </div>
      {hasFilters && !filteredCampaigns.length ? (
        <EmptyPlaceholder
          title="No results found"
          subtitle="There were no results that match your criteria"
          imageSrc={NoResultsImage}
          actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
        />
      ) : (
        <CampaignsTable
          isLoading={isLoadingCampaigns}
          campaigns={currentPageCampaigns}
        />
      )}
    </>
  );
}
